<template>
  <v-container>
    <v-row class="left">
      <v-col class="mb-4">
        <p class="text-h5">
          Wyszukiwarka użytkowników Moodle
        </p>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="formValid">
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="query"
            :rules="fieldQuery"
            label="Imię / nazwisko / e-mail"
            required
            v-on:keyup.enter="findUsers"
          ></v-text-field>
        </v-col>
        <v-col md="5"></v-col>
        <v-col md="3">
          <v-text-field
            v-model="numberOfDays"
            :rules="fieldDays"
            label="Ilość dni"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="left">
        <v-col md="4">
          <v-btn
            color="primary"
            elevation="1"
            class="mr-4"
            :loading="loading"
            @click="findUsers"
          >
            Wyszukaj użytkowników
          </v-btn>
        </v-col>
        <v-col md="2"> </v-col>
        <v-col md="6" align="right">
          <v-btn
            class="ma-1"
            color="primary"
            :disabled="!users.length || !selected.length"
            @click="enrollUsers"
            :loading="loadingEnroll"
          >
            Przedłuż dostęp
          </v-btn>
          <v-btn
            class="ma-1"
            color="error"
            :disabled="!users.length || !selected.length"
            @click="deleteUsers"
            :loading="loadingDelete"
          >
            Usuń
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="left">
      <v-col class="mb-4">
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          item-key="username"
          :loading="loading"
          v-model="selected"
          single-select
          show-select
          class="elevation-1"
        >
          <template v-slot:item.group-1="{ item }">
            <v-icon v-if="item.enrolledCourses.indexOf('SKP_SZK_PODST') > -1">
              mdi-check
            </v-icon>
          </template>
          <template v-slot:item.group-2="{ item }">
            <v-icon v-if="item.enrolledCourses.indexOf('SKP_SZK_ABUS') > -1">
              mdi-check
            </v-icon>
          </template>
          <template v-slot:item.group-3="{ item }">
            <v-icon v-if="item.enrolledCourses.indexOf('SKP_SZK_ADR') > -1">
              mdi-check
            </v-icon>
          </template>
          <template v-slot:item.group-4="{ item }">
            <v-icon v-if="item.enrolledCourses.indexOf('SKP_SZK_GAZ') > -1">
              mdi-check
            </v-icon>
          </template>
          <template v-slot:item.group-5="{ item }">
            <v-icon v-if="item.enrolledCourses.indexOf('SKP_SZK_DOD') > -1">
              mdi-check
            </v-icon>
          </template>

          <template v-slot:item.custom="{ item }">
            <v-btn
              class="ma-1"
              color="primary"
              outlined
              v-bind:href="
                'https://moodle.simp.edu.pl/user/profile.php?id=' + item.id
              "
              target="_blank"
            >
              Moodle
              <v-icon right>
                mdi-launch
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackbarStatus
      :show="snackbar.show"
      :color="snackbar.color"
      :message="snackbar.message"
    />
  </v-container>
</template>

<script>
import MoodleService from "@/api/MoodleService";
import common from "@/mixins/common";
import SnackbarStatus from "@/components/SnackbarStatus";

export default {
  name: "MoodleSearch",
  mixins: [common],
  components: {
    SnackbarStatus
  },
  data: () => ({
    formValid: false,
    numberOfDays: 180,
    query: null,
    loading: false,
    loadingEnroll: false,
    loadingDelete: false,
    users: [],
    search: "",
    selected: [],
    headers: [
      { text: "Imię", value: "firstname" },
      { text: "Nazwisko", value: "lastname" },
      { text: "E-mail", value: "email" },
      { text: "Grupa", value: "moodleGroup.name" },
      { text: "I", value: "group-1", width: "30px", sortable: false },
      { text: "II", value: "group-2", width: "30px", sortable: false },
      { text: "III", value: "group-3", width: "30px", sortable: false },
      { text: "IV", value: "group-4", width: "30px", sortable: false },
      { text: "V", value: "group-5", width: "30px", sortable: false },
      { text: "", align: "end", value: "custom", sortable: false }
    ],
    fieldQuery: [v => console.log(v) || !!v || "Pole wymagane"],
    fieldDays: [
      v => !!v || "Pole wymagane",
      v =>
        (!isNaN(parseFloat(v)) && v > 10 && v <= 360) ||
        "Ilość dni powinna być w przedziale 10-360"
    ]
  }),
  methods: {
    enrollUsers() {
      this.$refs.form.validate();
      this.loadingEnroll = true;
      if (this.formValid) {
        console.log(this.selected);
        MoodleService.enrollUser(this.selected[0].id, this.numberOfDays)
          .then(res => {
            console.log(res);
            this.loadingEnroll = false;
            this.findUsers();
          })
          .catch(error => {
            console.log(error);
            this.loadingEnroll = false;
            this.showSnackbar("Request to API failed!", "warning");
          });
      }
    },
    deleteUsers() {
      this.$refs.form.validate();
      this.loadingDelete = true;
      if (this.formValid) {
        console.log(this.selected);
        MoodleService.deleteUser(this.selected[0].id)
          .then(res => {
            console.log(res);
            this.loadingDelete = false;
            this.findUsers();
          })
          .catch(error => {
            console.log(error);
            this.loadingDelete = false;
            this.showSnackbar("Request to API failed!", "warning");
          });
      }
    },
    findUsers(event) {
      this.$refs.form.validate();
      console.log(event);
      if (this.formValid) {
        this.loading = true;
        this.users = [];
        MoodleService.findUsers(this.query)
          .then(res => {
            console.log(res);
            this.loading = false;
            this.users = res;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.showSnackbar("Request to API failed!", "warning");
          });
      }
    }
  }
};
</script>
